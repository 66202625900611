import { filter, first, tap } from 'rxjs';
import { PopupService } from '../popup';
import { ConfirmPopupComponent, ConfirmableModalData } from '../popups/confirm-popup/confirm-popup.component';
import { SharedModule } from '../shared.module';

export function Confirmable(
    data: ConfirmableModalData = {
        messageKey: 'FILTERS.modal.deleteConfirmation.message',
        confirmKey: 'button.yes',
        cancelKey: 'button.no',
    },
) {
    return function (target: object, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: unknown[]) {
            const skip = (<{ skipConfirm?: boolean }>this).skipConfirm || false;
            if (skip === true) {
                return originalMethod.apply(this, args);
            } else {
                const popupService = SharedModule.injector.get<PopupService>(PopupService);

                popupService.open(ConfirmPopupComponent, data);

                popupService.closingSubject
                    .pipe(
                        first(),
                        filter((closeReason) => closeReason === 'confirm'),
                        tap(() => {
                            return originalMethod.apply(this, args);
                        }),
                    )
                    .subscribe();
            }
        };

        return descriptor;
    };
}
